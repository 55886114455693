.navigation-bar  {
    color: var(--darkgrey);
    background: var(--main-bg-color);
    justify-content: right;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 10000;
}

.navigation-bar-desktop {
    display: flex;
    padding: 12px 42px 12px 0;
}

.navigation-bar-mobile {
    display: flex;
}
