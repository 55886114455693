@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');

:root {
    --black: #222222;
    --main-bg-color: #f5f7f8;
    --grey: #e1e1e1;
    --accent: #445296;
    --accent-light: #d3f2ff;
    --darkblue: #091659;
    --darkgrey: #444444;
}

textarea {
    padding: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 4px;
    height: 75px;
    resize: vertical;
    min-height: 75px;
    max-height: 200px;
    font-family: 'Source Sans Pro', serif;
}

input {
    padding: 6px;
    border-width: 2px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 4px;
}

button {
    font-weight: bold;
    background: lightgrey;
    padding: 8px;
    border-width: 2px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 4px;
    float: right;
}

button:active {
    color: white;
    background: darkgray;
    border-color: darkgray;
}

button:disabled {
    pointer-events: none;
}

button:hover {
    cursor: pointer;
}

input:focus, textarea:focus {
    outline: none !important;
    border-style: solid;
    border-color: var(--accent);
}

ul {
    list-style-position: inside;
    list-style-type: circle;
}

body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--main-bg-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    scroll-behavior: smooth
}
