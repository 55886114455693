.text-section {
    padding: 0 20% 0 20%;
}

.text-section-headline {
    position: relative;
    z-index: -1;
    color: var(--accent);
    border-bottom: 2px solid;
    padding-bottom: 4px;
}

.text-section-headline > span {
    position: absolute;
    top: -180%;
}

#portrait {
    max-width: 252px;
    border-radius: 50%;
}

.portrait-image {
    flex-basis: 100%;
    order: 1;
}

.left-text {
    padding-right: 20px;
}

.image-text-container {
    display: flex;
    align-items: center;
}

.next-event {
    padding: 4%;
    margin-bottom: 14px;
    background-color: var(--grey);
    display: none;
}

.event-headline {
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .text-section {
        padding: 0 5% 0 5%;
    }

    .image-text-container {
        display: block;
    }

    .portrait-image {
        text-align: center;
    }
}

@media screen and (max-width: 409px) {
    .text-section {
        padding: 0 5% 0 5%;
    }

    .image-text-container {
        display: block;
    }

    .portrait-image {
        text-align: center;
    }
}

@media screen and (max-width: 367px) {
    .text-section {
        padding: 0 3% 0 3%;
    }

    .image-text-container {
        display: block;
    }

    .portrait-image {
        text-align: center;
    }
}
